<!-- @component
  TODO: Component description
 -->
<script>
  import IsMobile from "../../utils/IsMobile.svelte";

  let isTouch;
</script>

<style lang="scss">/* Bulma Utilities */
.intro-text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #2b3137;
  color: white;
  padding-top: 10em;
  padding-bottom: 10em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.title {
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", BlinkMacSystemFont, -apple-system, sans-serif;
  font-weight: 300;
  color: white;
  line-height: 1.2em;
  text-align: center;
}
.title em {
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", BlinkMacSystemFont, -apple-system, sans-serif;
}
.title strong {
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", BlinkMacSystemFont, -apple-system, sans-serif;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 9vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .title {
    font-size: 3em;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .title {
    font-size: 3em;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .title {
    font-size: 3em;
  }
}
@media screen and (min-width: 1408px) {
  .title {
    font-size: 4em;
  }
}
.title:last-child {
  margin-bottom: 0;
}

p {
  max-width: 768px;
  margin-top: 3em;
  text-align: center;
}
p strong {
  color: white;
}

.quote-author {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quote-author img {
  border-radius: 50%;
  width: 39px;
}
.quote-author p {
  margin: 0;
  margin-top: 0.5rem;
  line-height: 1rem;
}</style>

<div class="intro-text-section">
  <h1 class="title rellax" data-rellax-percentage="0.5" data-rellax-speed="1">
    No more chaos.
    {#if isTouch}
      <br />
    {/if}
    Say
    <em>hi</em>
    to
    <strong>focus.</strong>
  </h1>
  <p>
    Creation Station is your complete digital co-creation workstation. Collect
    data, research, come up with brilliant insights, ideate, make decisions and
    innovate. Creation Station makes it easy to collaborate remotely and manage
    Design Thinking and creation projects from start to finish.
  </p>
  <p>
    <em>
      "Creation Station takes the manual repetitive grunt work out of Design
      Thinking, so the team has more time to focus on what adds most value:
      analysis and ideation."
      <p><br>
        <strong>Strategist & Researcher</strong>
        </p>
    </em>
</div>

<IsMobile bind:isTouch />
