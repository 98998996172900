<style lang="scss">/* Bulma Utilities */
.subtitle {
  font-size: 1em;
  text-align: center;
  color: #2b3137;
  margin-top: 4em;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 3em;
}</style>

<p class="subtitle">
  Help us make Creation Station the best it can be.
  <br />
  Fund our roadmap or work with us to create a custom workflow for your organisation.
</p>
