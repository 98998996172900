<script>
  import GenerateIcon from "../../components/sectionIcons/GenerateIcon.svelte";
  import Triangle from "../../components/Triangle.svelte";
  import IsMobile from "../../utils/IsMobile.svelte";

  let isMobile;
</script>

<style lang="scss">/* Bulma Utilities */
.section {
  position: relative;
  width: 100%;
}

.section-background {
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .section-background {
    display: none;
  }
}

.image-background {
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
  background-color: #fff;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  max-width: 600px;
}

.image-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
}
.image-container.mobile {
  margin-bottom: 3rem;
  margin-left: 0;
}
.image-container.mobile .image {
  margin-right: 0;
}

.image {
  max-height: 60rem;
  margin-right: 10rem;
}

hr {
  background-color: none;
  border-top: 2px dotted #ff9e71;
  opacity: 0.5;
}

p.quote {
  color: #8c97a4;
  font-size: 0.9em;
}

p {
  color: #2b3137;
  font-size: 1.1em;
}
@media screen and (max-width: 768px) {
  p {
    text-align: center;
  }
}

strong {
  color: #2b3137;
}

.title {
  margin-top: 0 !important;
}

.image-parent-container {
  padding: 0;
}</style>

<section class="section">
  <div class="section-foreground">
    <div class="columns">
      <div
        class="column is-8 rellax image-parent-container"
        data-rellax-speed="2.5"
        data-rellax-percentage="0.5">
        {#if !isMobile}
          <div class="image-container">
            <img class="image" src="img/screens/generate.png" alt="" />
          </div>
        {/if}
      </div>
      <div class="column">
        <div class="content">
          {#if isMobile}
            <div class="image-container mobile">
              <img class="image" src="img/screens/generate.png" alt="" />
              <div class="image-background">
                <Triangle
                  class="rellax"
                  percentage="0"
                  speed="-2"
                  positionX="-63vw"
                  positionY="10%"
                  fill="#79b9ff"
                  rotation="200"
                  width="30vw" />

                <Triangle
                  class="rellax"
                  percentage="0"
                  speed="-1"
                  positionX="-60vw"
                  positionY="50%"
                  fill="#b879ff"
                  rotation="230"
                  width="30vw" />
              </div>
            </div>
          {/if}

          <div class="section-icon">
            <GenerateIcon />
          </div>
          <h2 class="title">Generate</h2>
          <p>
            Generate ideas for analysis; or collect quotations, images,
            observations and other notes - all in one place.
          </p>

          <hr />
          <p class="quote">
            <em>
              "When developing ideas in collaboration with others, with
              something like Creation Station, I enjoy seeing how people's ideas
              overlap and diverge, and the ambitious ideas we develop when given
              the chance to express them. It helps me remember why I build the
              things I build - to realise possibilities, to address the concerns
              we all share."
              <p><br>
                <strong>Software Architect</strong>
                </p>
            </em>
          </div>
      </div>
    </div>
  </div>

  <div class="section-background">
    <Triangle
      class="rellax"
      percentage="0"
      speed="-2"
      positionX="-63vw"
      positionY="10%"
      fill="#79b9ff"
      rotation="200"
      width="30vw" />

    <Triangle
      class="rellax"
      percentage="0"
      speed="-1"
      positionX="-60vw"
      positionY="50%"
      fill="#ff9e71"
      rotation="230"
      width="30vw" />
  </div>
</section>

<IsMobile bind:isMobile />
