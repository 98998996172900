<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  import Layout from "../sections/Layout.svelte";
  import Animation from "../components/Animation.svelte";
  import IsMobile from "../utils/IsMobile.svelte";

  let duration;
  let TIMESCALE;
  let visited = false;
  let showSite = false;
  let isMobile;

  $: if (!visited && duration !== undefined && TIMESCALE !== undefined) {
    setTimeout(() => {
      showSite = true;
    }, (duration / TIMESCALE) * 1000);
  }

  $: if (visited || isMobile) {
    showSite = true;
  }

  onMount(() => {
    if (localStorage.getItem("visited")) {
      visited = localStorage.getItem("visited") == "true";
    }

    localStorage.setItem("visited", true);
  });
</script>

<style lang="scss">.page {
  width: 100%;
  overflow: hidden;
}</style>

{#if showSite}
  <div class="page" transition:fade="{{ duration: 1000 / TIMESCALE }}">
    <Layout />
  </div>
{:else}
  <Animation bind:duration bind:TIMESCALE />
{/if}

<IsMobile bind:isMobile />
