<!-- @component
  Privacy Policy container
 -->
<style lang="scss">.container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.container .contents {
  margin: 2rem;
  max-width: 665px;
}</style>

<div class="container">
  <div class="contents">
    <slot />
  </div>
</div>
