<script>
  import Container from "../components/compliance/Container.svelte"
</script>
<style lang="scss">h1, h2, h3 {
  margin-top: 1.22em;
}

h1, h2 {
  text-transform: uppercase;
}

h3 {
  font-weight: 600;
}

p, li {
  color: #000;
}

h1 {
  font-size: 1.875rem;
  line-height: 1.35;
  font-weight: bold;
}

p {
  font-size: 0.8125rem;
  font-weight: 400;
  margin: 1.1875rem 0;
  line-height: 1.22rem;
}

ul, ol {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.22rem;
  list-style: initial;
}

h2 {
  font-size: 1.5625rem;
  line-height: 1.35;
  font-weight: bold;
}

li {
  margin-left: 1.1875rem;
}</style>


<Container>
<h1>PRIVACY POLICY</h1>
<p>Effective date: 7 July 2021</p>
<p>Revised date: 7 July 2021</p>
<p><strong>ALSKA DIGITAL PTY LTD (ACN 651 738 064)</strong> is a Company committed to maintaining solid privacy protections for all of its users. Our Privacy Policy is created to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Services.</p>
<p>Our policy on data protection and privacy is based on the principles of Security, Self-Responsibility, and Legal Compliance.</p>
<p>For the purposes of this Privacy Policy:</p>
<ul>
<li>“Site” refers to the Creation Station™ website, which can be accessed at <a
  href="https://creationstation.io"
  rel="nofollow"
>creationstation.io</a>.</li>
<li>“Service” refers to the Creation Station™ web application <a
  href="https://app.creationstation.io"
  rel="nofollow"
>app.creationstation.io</a>, which can be accessed via our Site.</li>
<li>The terms “we,” “us,” and “our” refer to the ALSKA DIGITAL PTY LTD Company.</li>
<li>“You” refers to our client, whether individual or corporation, as a user of our Service.</li>
</ul>
<p>By accessing our Service, you accept our <a
  href="https://creationstation.io/privacy-policy"
  rel="nofollow"
>Privacy Policy</a> and <a
  href="https://creationstation.io/terms-of-service"
  rel="nofollow"
>Terms of Service</a> and you consent to our collection, storage, use and disclosure of your Personal Information as described in this Privacy Policy.</p>
<h2>INFORMATION COLLECTED</h2>
<p>We collect personal information that you voluntarily provide to us when registering with our Services, expressing an interest in obtaining information about us or our products and services, when participating in activities using our Services (such as participating in online activities) or otherwise contacting us. The personal information that we collect depends on the context of your interactions with us and the Sites, the choices you make and the products and features you use.</p>
<p>The personal information we collect may include the following:</p>
<ul>
<li>Contact Email: Your email address.</li>
</ul>
<h3>Automatically and anonymously collected information</h3>
<p>We automatically collect certain information when you visit, use or navigate our Services. This information does not reveal your specific identity (such as your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
<h2>COOKIES AND SIMILAR TECHNOLOGIES</h2>
<p>We may use cookies and similar tracking technologies to access or store information.</p>
<ul>
<li>Site preferences cookies: To provide you with a great experience on our site we provide the functionality to set your preferences for how our site runs when you use it. In order to remember your preferences, we set cookies so that this information can be called whenever you interact with a page that is affected by your preferences.</li>
<li>Third party cookies: In some special cases we also use cookies provided by trusted third parties. The following details which third party cookies you might encounter through our site. <ul>
<li>Google Analytics: Our site uses Google Analytics. These cookies may track things such as how long you spend on our site and the pages that you visited so we can continue to produce engaging content. For more information on Google Analytics cookies, see the <a
  href="https://policies.google.com/privacy?hl=en-US"
  rel="nofollow"
>Google Analytics Privacy Policy</a>.</li>
</ul></li>
</ul>
<h2>USE OF INFORMATION</h2>
<p>We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests (“Business Purposes”), in order to enter into or perform a contract with you (“Contractual”), with your consent (“Consent”), and/or for compliance with our legal obligations (“Legal Reasons”). We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
<p>We use the information we collect or receive:</p>
<ul>
<li>To send you marketing and promotional communications. We may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the “Your Privacy Rights” below).</li>
<li>To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</li>
<li>Fulfil and manage your orders. We may use your information to fulfil and manage your orders, payments, returns, and exchanges made through the Sites.</li>
<li>To post testimonials, with your expressed Consent. We post testimonials on our Sites that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your testimonial, contact us at <a
  href="mailto:hello@creationstation.io"
>hello@creationstation.io</a> and be sure to include your name, testimonial location, and contact information.</li>
<li>Request Feedback. We may use your information to request feedback and to contact you about your use of our Sites.</li>
<li>To protect our Sites. We may use your information as part of our efforts to keep our Sites safe and secure (for example, for fraud monitoring and prevention).</li>
<li>To enforce our terms, conditions and policies.</li>
<li>To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>
<li>For other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites, products, services, marketing and your experience.</li>
</ul>
<h2>INFORMATION SHARING</h2>
<p>We do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent.</p>
<p>We will only share Personal Information with outside parties if required to meet any applicable legal process or enforceable governmental request; to enforce applicable Terms of Service, including investigation of potential violations; address fraud, security or technical concerns; or to protect against harm to the rights, property, or safety of our users or the public as required or permitted by law.</p>
<p>We only share and disclose your information in the following situations:</p>
<ul>
<li>Compliance with Laws: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
<li>Vital Interests and Legal Rights: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
<li>Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
<li>With your Consent: We may disclose your personal information for any other purpose with your consent.</li>
</ul>
<h2>INFORMATION RETENTION</h2>
<p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law. </p>
<p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise it, or, if this is not, for whatever reason, possible, then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
<h2>INFORMATION SECURITY</h2>
<p>We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process. Even though we will continue to do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the services within a secure environment.</p>
<p>We protect your information from potential security breaches by implementing technological security measures such as encryption, firewalls and SSL. Your data is stored in a top-tier, third-party data hosting provider, AWS (Amazon Web Services) and MongoDB Atlas Cloud. </p>
<h2>TRANSFERENCE OF YOUR INFORMATION</h2>
<p>Our primary servers are located in Australia. If you are accessing our Sites from outside Australia, be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information, in Australia and other countries.</p>
<h2>THIRD-PARTY WEBSITES</h2>
<p>As part of our Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Site and the Service. Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link on our Site or via our Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.</p>
<h2>PRIVACY RIGHTS</h2>
<p>We are relying on your consent to process your personal information, and you have the right to withdraw your consent at any time. Note however that this will not affect the lawfulness of the processing before its withdrawal.</p>
<h3>Cookies and similar technologies</h3>
<p>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this may affect your experience with our Site or Services. </p>
<h3>Opting out of email marketing</h3>
<p>You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. You can also opt-out by:</p>
<ul>
<li>Noting your preferences at the time you register your account with the Services.</li>
<li>Logging into your account settings and updating your preferences.</li>
<li>Contacting us using the contact information provided below.</li>
</ul>
<p>If you’re not happy with how we are processing your personal data, contact our Chief Operating Officer by emailing <a
  href="mailto:hello@creationstation.io"
>hello@creationstation.io</a>. We will review and investigate your complaint and get back to you within a reasonable time frame.</p>
<h2>CHANGES TO THIS PRIVACY POLICY</h2>
<p>We reserve the right to revise this Privacy Policy or any part of it from time to time. Review this Privacy Policy periodically for changes. Any revised policy will be placed on our website at <a
  href="https://creationstation.io/privacy-policy"
  rel="nofollow"
>app.creationstation.io/privacy-policy</a>. </p>
<p>Your continued use of our website, services, requesting our assistance, or the provision of further personal information to us after this Privacy Policy has been revised, constitutes your acceptance of the revised Privacy Policy.</p>
<p>The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>
<h2>HOW TO CONTACT US</h2>
<p>If you have any questions about this Privacy Policy, contact our Chief Operating Officer:</p>
<ol>
<li>by email to <a href="mailto:hello@creationstation.io">hello@creationstation.io</a> with the email Subject “Creation Station Privacy Policy Enquiry”.</li>
</ol>
</Container>
