<style lang="scss">/* Bulma Utilities */
.title {
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", BlinkMacSystemFont, -apple-system, sans-serif;
  font-weight: 300;
  color: #fff;
  line-height: 1em;
}
.title strong {
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 9vw;
    text-align: center;
    color: #fff;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .title {
    font-size: 2em;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .title {
    font-size: 2em;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .title {
    font-size: 3em;
  }
}
@media screen and (min-width: 1408px) {
  .title {
    font-size: 4em;
  }
}
.title:last-child {
  margin-bottom: 0;
}

.title-container {
  margin-left: 2em;
  margin-right: 2em;
}
@media screen and (max-width: 768px) {
  .title-container {
    width: 100%;
    margin: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}</style>

<div class="title-container">
  <h1 class="title">Try Creation Station.</h1>
  <h1 class="title">
    <strong>Join the Beta!</strong>
  </h1>
</div>
