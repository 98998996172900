<script>
  import { fbButton, linkedin, tw, email } from "vanilla-sharing";
  import Icon from "@iconify/svelte";
  import facebookIcon from "@iconify-icons/cib/facebook";
  import linkedinWithCircle from "@iconify-icons/entypo-social/linkedin-with-circle";
  import twitterWithCircle from "@iconify-icons/entypo-social/twitter-with-circle";
  const url = "https://creationstation.io";
  const title = "Creation Station";
  const hashtag = "alskadigital";
  const description =
    "Creation Station is your complete Design Thinking workstation.";
  const handleLinkedin = () => {
    linkedin({
      url,
      title,
      description,
    });
  };
  const handleTwitter = () => {
    tw({
      url,
      title,
      hashtags: [hashtag],
    });
  };
  const handleFacebook = () => {
    fbButton({
      url,
    });
  };
  const handleEmail = () => {
    email({
      url,
      title,
      description,
    });
  };
</script>

<style lang="scss">.socials-container {
  display: flex;
  justify-content: center;
}
.socials-container .social-icon {
  cursor: pointer;
}
.socials-container > .social-icon:not(:last-child) {
  margin-right: 1.25rem;
}</style>

<div class="socials-container">
  <div class="social-icon" on:click="{handleFacebook}">
    <Icon height="{23}" width="{23}" color="#79b9ff" icon="{facebookIcon}" />
  </div>

  <div class="social-icon" on:click="{handleLinkedin}">
    <Icon
      height="{23}"
      width="{23}"
      color="#ff9e71"
      icon="{linkedinWithCircle}" />
  </div>

  <div class="social-icon" on:click="{handleTwitter}">
    <Icon
      height="{23}"
      width="{23}"
      color="#b879ff"
      icon="{twitterWithCircle}" />
  </div>

  <div class="social-icon" on:click="{handleEmail}">
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 0C5.14841 0 0 5.14841 0 11.5C0 17.8509 5.14841 23 11.5 23C17.8516 23 23 17.8509 23 11.5C23 5.14841 17.8516 0 11.5 0Z"
        fill="#69D197"></path>
      <path
        d="M4.68481 15.6796L8.41441 10.9904L4.68481 8.71057V15.6796Z"
        fill="white"></path>
      <path
        d="M14.0157 11.3175L11.4921 12.8714L8.96429 11.3261L5.11035 16.1719H17.8732L14.0157 11.3175Z"
        fill="white"></path>
      <path
        d="M11.4914 12.121L18.3152 7.91847V6.82812H4.68481V7.96088L11.4914 12.121Z"
        fill="white"></path>
      <path
        d="M14.564 10.9796L18.3151 15.6997V8.66956L14.564 10.9796Z"
        fill="white"></path>
    </svg>
  </div>
</div>
