<script>
  import IsMobile from "../../../utils/IsMobile.svelte";

  let isMobile;
</script>

<style lang="scss">/* Bulma Utilities */
.subtitle-container {
  margin-top: 3em;
}

.subtitle {
  font-size: 1em;
  text-align: center;
  font-family: "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", BlinkMacSystemFont, -apple-system, sans-serif;
  color: #8c97a4;
}
.subtitle > * {
  color: #8c97a4;
}</style>

<div class="subtitle-container rellax" data-rellax-speed="1">
  <p class="subtitle">
    <strong>Creation Station</strong>
    is your complete
    {#if isMobile}
      <br />
    {/if}
    Design Thinking workstation.
  </p>
</div>

<IsMobile bind:isMobile />
