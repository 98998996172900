<script>
  import Container from "../components/compliance/Container.svelte"
</script>
<style lang="scss">h1, h2, h3 {
  margin-top: 1.22em;
}

h1, h2 {
  text-transform: uppercase;
}

h3 {
  font-weight: 600;
}

p, li {
  color: #000;
}

h1 {
  font-size: 1.875rem;
  line-height: 1.35;
  font-weight: bold;
}

p {
  font-size: 0.8125rem;
  font-weight: 400;
  margin: 1.1875rem 0;
  line-height: 1.22rem;
}

ul, ol {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.22rem;
  list-style: initial;
  margin-top: 1.1875rem;
  margin-bottom: 1.1875rem;
}

h2 {
  font-size: 1.5625rem;
  line-height: 1.35;
  font-weight: bold;
}

li {
  margin-left: 1.1875rem;
}</style>


<Container>
<h1>Creation Station™ Terms of Service</h1>
<p>Last Modified: 7 July 2021</p>
<h2>Overview</h2>
<p>Thank you for visiting <strong>Creation Station™</strong>, your complete co-creation workstation made by ALSKA DIGITAL PTY LTD (ACN 651 738 064). </p>
<p>These Terms of Service must be agreed to completely as they constitute a contract between you and Alska Digital, which you agree to adhere to as a condition of accessing our Site and Services. </p>
<h2>Definitions</h2>
<ul>
<li>“Agreement” means these Terms of Service;</li>
<li>“Alska Digital” refers to our company, known as “ALSKA DIGITAL PTY LTD”; our Site, which is located at <a
  href="https://creationstation.io"
  rel="nofollow"
>creationstation.io</a>; or our Service, which includes our Site as well as the Service, which is located at <a
  href="https://app.creationstation.io"
  rel="nofollow"
>app.creationstation.io</a> or other services provided by Alska Digital; or a combination of some or all of the three aforementioned things, depending on the context of the word;</li>
<li>“Service” refers to our Site, which is located at <a
  href="https://creationstation.io"
  rel="nofollow"
>creationstation.io</a>, and the services that we provide through our Site, including our co-creation software and any other software that we may provide;</li>
<li>“Site” refers to our website, which is located at <a
  href="https://creationstation.io"
  rel="nofollow"
>creationstation.io</a>;</li>
<li>“User” refers to anyone who uses our Service, as well as general visitors to our Site;</li>
<li>“You” refers to you, the person who is entering into this Agreement with Alska Digital, and includes the organisation, if any, that you’re signing up to Alska Digital.</li>
</ul>
<h2>Eligibility</h2>
<p>In order to use our Service, you must meet a number of conditions, including but not limited to the following:</p>
<ul>
<li>You must not be in violation of any embargoes, export controls, or other laws of Australia or other countries having jurisdiction over this Agreement, Alska Digital, and yourself. Visit the website of the Australian Government’s Department of Foreign Affairs and International Trade for more information.</li>
<li>You must be the minimum age required to enter into a contract in the area in which you reside, and, in any event, must not be less than 18 years of age.</li>
<li>You must provide us with personal information (such as your email), and other information that we deem necessary to provide you with our Service.</li>
</ul>
<h2>Nature of Service</h2>
<p>We do not make any representations about the effectiveness of our Service or any content produced within Alska Digital by the mere fact that they arose from using Creation Station™. You agree that you are solely responsible for the use of any content or discussions regardless of whether they arose within Creation Station™ or elsewhere.</p>
<h3>Licence to use</h3>
<p>Once you have signed up to Creation Station™, there are additional rules which govern the use of our Service. You must <u>NOT</u>:</p>
<ul>
<li>Violate the laws of Australia, its states or territories, or any foreign political entity having jurisdiction over this Agreement, whether or not the foreign political entity is a country or a subdivision (such as a state or province) or municipality (such as a city, town, county, or region) of a foreign country.</li>
<li>Post or send anything violent, threatening, pornographic, racist, hateful, or otherwise objectionable according to the opinion of Alska Digital or its delegates.</li>
<li>Be fraudulent or negligent in your representations to Alska Digital, its Users, or any other third party.</li>
<li>Infringe on anyone’s intellectual property rights, defame anyone, impersonate anyone, or otherwise violate the rights of a third party.</li>
<li>Hack, crack, phish, SQL inject, or otherwise compromise the security or integrity of the Site, Service, or its Users’ computers.</li>
<li>Do anything else which, at Alska Digital’s sole discretion, may bring Alska Digital into disrepute.</li>
<li>Establish illegite accounts, create multiple accounts or circumvent an account ban with the creation of new accounts, unless you have written approval from Alska Digital to do so.</li>
</ul>
<h2>Interruptions Possible</h2>
<p>The Service operational at <a
  href="https://app.creationstation.io"
  rel="nofollow"
>app.creationstation.io</a> is as of 4 February 2021 in a Beta (Prototype/Proof-of-Concept) state. Therefore, by using the Service you accept the following terms:  </p>
<ul>
<li>All activity sessions created using the Service are carried out on a unique URL which is accessible to anyone on the internet who has access to the URL. </li>
<li>All activity data is automatically (and permanently) deleted from the Service 30 days after the activity is completed. </li>
</ul>
<p>Our Service may be interrupted at any time, for application updates, server maintenance, security purposes, business purposes, or any other reason that Alska Digital may deem advisable. If we have advance notice of such downtime, we will usually attempt to notify you, but we make no promises that we will do so as it may sometimes be necessary to not notify Users (such as for security purposes), or be unreasonably burdensome upon us to do so. You therefore acknowledge and agree that our Service may not always be available and that this will not impose any liability on us for compensation for non-delivery of our Service if it occurs for less than twelve hours at a time.</p>
<h2>Our Copyright</h2>
<p>Alska Digital must be assured that it has the right to use any content that is posted to its Site by its Users. Whenever submitting content to our website, you agree that you are granting us a non-exclusive, universal, perpetual, irrevocable, sublicensable, commercial and non-commercial right to use the content that you submit to us to the extent that we deem it necessary to provide you with our Service. You warrant to us that you have the right to grant us this right over the content, and that you will indemnify us for any loss resulting from a breach of this warranty and defend us against claims regarding the same.</p>
<h2>Revocation of Consent</h2>
<p>We may revoke our consent for your use of our intellectual property, or any other permission granted to you under this Agreement, at any time. You agree that if we so request, you must take immediate action to remove any usage of our intellectual property that you may have engaged in, even if it would cause a loss to you.</p>
<h2>Copyright & Trademark Infringement</h2>
<p>Users must not post any information that infringes on anyone’s copyright. If you believe that your copyright has been infringed, send us a message which contains:</p>
<ul>
<li>Your name.</li>
<li>The name of the party whose copyright has been infringed, if different from your name.</li>
<li>The name and description of the work that is being infringed.</li>
<li>The location on our website of the infringing copy.</li>
<li>A statement that you have a good faith belief that use of the copyrighted work described above is not authorised by the copyright owner (or by a third party who is legally entitled to do so on behalf of the copyright owner) and is not otherwise permitted by law.</li>
<li>A statement that you swear, under penalty of perjury, that the information contained in this notification is accurate and that you are the copyright owner or have an exclusive right in law to bring infringement proceedings with respect to its use.</li>
</ul>
<p>You must sign this notification and send it to our Chief Operating Officer at <a
  href="mailto:hello@creationstation.io"
>hello@creationstation.io</a>. Electronic signatures are accepted.
We recommend that you send us similar information to that above in regards to any allegation of trademark infringement, and we will address it as soon as practicable.
By submitting a takedown notice which follows the above “DMCA” format, you agree to hold us harmless for any damages for intellectual property infringement against you which may have been committed by a third party on our Site or Service, regardless of in which court you may have brought suit against us.
If you do not use the above “DMCA” format, you agree to submit irrevocably and exclusively to the jurisdiction of Australia’s courts and laws by sending us a takedown notice in any form, in relation to the subject matter of that takedown notice.</p>
<h2>Representations & Warranties</h2>
<p>We make no representations or warranties as to the merchantability of our service or fitness for any particular purpose. You agree that you are releasing us from any liability that we may otherwise have to you in relation to or arising from this agreement or our services, for reasons including, but not limited to, failure of our service, negligence or any other tort. To the extent that applicable law restricts this release of liability, you agree that we are only liable to you for the minimum amount of damages that the law restricts our liability to, if such a minimum exists.</p>
<p>We are not responsible in any way for damages caused by third parties who may use our services, including but not limited to people who commit intellectual property infringement, defamation, tortious interference with economic relations or any other actionable conduct towards you.</p>
<p>We are not responsible for any use of the information generated or disseminated using Creation Station™, nor are we responsible for any damages which arise from the proper or improper use of Creation Station™, including any altered version of our service.</p>
<p>We are not responsible for any failure on your part to provide a computer system adequate for the purpose of running our software. Ensure you have up to date software and hardware before purchasing our service.</p>
<p>We are not liable for any failure of the goods or services of our company or a third party, including any failures or disruptions, untimely delivery, scheduled or unscheduled, intentional or unintentional, on our website which prevent access to our website temporarily or permanently.</p>
<p>The provision of our service to you is contingent on your agreement with this and all other sections of this agreement. Nothing in the provisions of this “Representation and Warranties” section shall be construed to limit the generality of the first paragraph of this section.</p>
<p>For Jurisdictions that do not allow us to limit our liability: Notwithstanding any provision of these Terms, if your jurisdiction has provisions specific to waiver or liability that conflict with the above then our liability is limited to the smallest extent possible by law. Specifically, in those jurisdictions not allowed, we do not disclaim liability for: (a) death or personal injury caused by its negligence or that of any of its officers, employees or agents; or (b) fraudulent misrepresentation; or (c) any liability which it is not lawful to exclude either now or in the future.</p>
<h2>Indemnity</h2>
<p>You agree to indemnify and hold us harmless for any claims by you or any third party which may arise from or relate to this Agreement or the provision of our service to you, including any damages caused by your use of our website or acceptance of the offers contained on it. You also agree that you have a duty to defend us against such claims and we may require you to pay for an attorney(s) of our choice in such cases. You agree that this indemnity extends to requiring you to pay for our reasonable attorneys’ fees, court costs, and disbursements. In the event of a claim such as one described in this paragraph, we may elect to settle with the party/parties making the claim, and you shall be liable for the damages as though we had proceeded with a trial. If you and a third party (such as another User) both incur the kinds of claims mentioned in this paragraph against Alska Digital through a combination of your activities (such as while both collaborating together using Creation Station™), you agree that you shall be jointly and severally liable for the kind of indemnity mentioned herein.</p>
<h2>Choice of Law</h2>
<p>This Agreement, and any disputes relating to Creation Station™ in general, shall be governed by the laws in force in Australia. The offer and acceptance of this contract are deemed to have occurred in Australia.</p>
<h2>Forum of Dispute</h2>
<p>You agree that any dispute arising from or relating to this Agreement, or Alska Digital in general, will be heard solely by a court of competent jurisdiction in Australia. Specifically, where the subject matter of a dispute is eligible for it, you agree that any disputes shall be heard solely within the lowest court that has the authority to hear your claim (“Small Claims Court”). Depending on your jurisdiction, this will often be handled by a lower level of the “Civil and Administrative Tribunal.” Where you do not otherwise have any nexus to an Australian state or territory, you agree that any disputes will be brought within Victoria Australia.</p>
<p>If a dispute claims multiple claims and one or more of those claims would be eligible to be heard by the Small Claims Court, you agree not to bring the other claims against us and to instead proceed within the Small Claims Court.</p>
<p>If you would be entitled in a dispute to an amount exceeding the monetary jurisdiction of the Small Claims Court, you agree to waive your right to collect any damages in excess of the monetary jurisdiction and instead still bring your claim within the Small Claims Court. </p>
<p>You agree that if a dispute is eligible to be heard in Small Claims Court but you would be entitled to an additional or alternative remedy in a higher court, such as injunctive relief, you will waive your right to that remedy and still bring the dispute within the Small Claims Court.</p>
<p>If you bring a dispute in a manner other than in accordance with this section, you agree that we may move to have it dismissed, and that you will be responsible for our reasonable attorneys’ fees, court costs, and disbursements in doing so.</p>
<p>With the exception of the case envisioned in the above paragraph (where you bring a dispute in violation of this Agreement), you agree that the attorneys’ fees, court costs, and disbursements relating to a case will be awarded according to the rules of the court in which the case is brought.</p>
<h2>Force Majeure</h2>
<p>You agree that we are not responsible to you for anything that we may otherwise be responsible for, if it is the result of events beyond our control, including, but not limited to, acts of God, war, insurrection, riots, terrorism, crime, labor shortages (including lawful and unlawful strikes), embargoes, postal disruption, communication disruption, failure or shortage of infrastructure, shortage of materials, or any other event beyond our control.</p>
<h2>Severability</h2>
<p>In the event that a provision of this Agreement is found to be unlawful, conflicting with another provision of the Agreement, or otherwise unenforceable, the Agreement will remain in force as though it had been entered into without that unenforceable provision being included in it.</p>
<p>If two or more provisions of this Agreement are deemed to conflict with each other’s operation, Alska Digital shall have the sole right to elect which provision remains in force.</p>
<h2>Non-Waiver</h2>
<p>Alska Digital reserves all rights afforded to us under this Agreement as well as under the provisions of any applicable law. Our non-enforcement of any particular provision or provisions of this Agreement or any applicable law should not be construed as our waiver of the right to enforce that same provision under the same or different circumstances at any time in the future.</p>
<h2>Termination & Cancellation</h2>
<p>We may terminate your account or access as well as access to our Site and Service to you at our discretion without explanation, though we will strive to provide a timely explanation in most cases. </p>
<p>Under no circumstances, including termination or cancellation of our Service to you, will we be liable for any losses related to actions of other Users, such as conduct which occurs during activities.</p>
<p>Note that even though we may terminate the provision of our Service to you, this Agreement will still remain in effect.</p>
<h2>Assignment of Rights</h2>
<p>You may not assign your rights and/or obligations under this Agreement to any other party without our prior written consent. We may assign our rights and/or obligations under this Agreement to any other party at our discretion.</p>
<h2>Amendments</h2>
<p>We may amend this Agreement from time to time. When we amend this Agreement, we will email you that there has been a change. If you do not agree to the changes, you must cease using our Site and Service immediately and inform us of your non-agreement with sufficient information to identify your account at <a
  href="mailto:hello@creationstation.io"
>hello@creationstation.io</a> so that we may disable your account.</p>
<h2>Australian Consumer Law</h2>
<p>Our Service complies with the Australian Consumer Law. You may read more about your rights when purchasing and using our Service by visiting <a
  href="https://www.consumerlaw.gov.au"
  rel="nofollow"
>consumerlaw.gov.au</a>. If you have any questions or concerns about our Service, you may contact us at <a
  href="mailto:hello@creationstation.io"
>hello@creationstation.io</a>.</p>
<h2>Corporate Information</h2>
<p>ALSKA DIGITAL PTY LTD is a proprietary limited company domiciled in the State of Victoria Australia and doing business under Australian Business Number 27 651 738 064.</p>
</Container>
