<script>
  import TryText from "./components/TryText.svelte";
  import TrySubtext from "./components/TrySubtext.svelte";

  import IsMobile from "../../utils/IsMobile.svelte";

  let isMobile;
</script>

<style lang="scss">/* Bulma Utilities */
.try-container {
  background-color: #2b3137;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.try-background-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .try-background-container {
    display: none;
  }
}
.try-background-container > svg {
  position: absolute;
}

.try-mobile-triangles {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vw;
  top: 0;
  z-index: -1;
  margin-top: 6em;
  margin-bottom: -35vw;
}
.try-mobile-triangles > svg {
  position: absolute;
}
@media screen and (min-width: 769px), print {
  .try-mobile-triangles {
    display: none;
  }
}

.try-foreground-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-top: 8em;
  padding-bottom: 8em;
}
@media screen and (max-width: 768px) {
  .try-foreground-container {
    padding-bottom: 0;
  }
}

.container {
  height: 100%;
}

.join-container {
  margin-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
  border-radius: 3px;
}

.join-container-mobile {
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.beta-container {
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  width: 100%;
}</style>

<div class="try-container" id="try-app">
  <div class="try-background-container">
    <svg
      width="100%"
      height="90%"
      viewBox="0 0 100 100"
      class="rellax"
      data-rellax-percentage="0.5">
      <polygon
        transform="translate(25)"
        points="0,100 100,100 100,0"
        fill="#53ebff"></polygon>
    </svg>
    <svg
      width="100%"
      height="90%"
      viewBox="0 0 100 100"
      class="rellax"
      data-rellax-percentage="0.5">
      <polygon
        transform="translate(130)"
        points="0,0 0,100 100,0"
        fill="#88ffbd"></polygon>
    </svg>
    <svg
      width="100%"
      height="90%"
      viewBox="0 0 100 100"
      class="rellax"
      data-rellax-percentage="0.5">
      <polygon
        transform="translate(137)"
        points="0,100 200,100 100,0"
        fill="#ff9e71"></polygon>
    </svg>
  </div>

  <div class="container">
    <div
      class="try-foreground-container rellax"
      data-rellax-percentage="0.5"
      data-rellax-speed="1">
      <TryText />
      <TrySubtext />
      {#if isMobile}
        <div class="join-container-mobile">
          <a class="button is-primary" href="https://app.creationstation.io/">
            <strong class="no-radius">Join the BETA</strong>
          </a>
        </div>
      {:else}
        <div class="join-container">
          <a
            class="button is-primary is-medium"
            href="https://app.creationstation.io/">
            <strong class="no-radius">Join the BETA</strong>
          </a>
        </div>
      {/if}
    </div>
  </div>

  <div class="try-mobile-triangles">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      class="rellax"
      data-rellax-percentage="0.5">
      <polygon
        transform="translate(-87)"
        points="0,100 100,100 100,0"
        fill="#53ebff"></polygon>
    </svg>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      class="rellax"
      data-rellax-percentage="0.5">
      <polygon transform="translate(20)" points="0,0 0,100 100,0" fill="#88ffbd"
      ></polygon>
    </svg>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      class="rellax"
      data-rellax-percentage="0.5">
      <polygon
        transform="translate(30)"
        points="0,100 200,100 100,0"
        fill="#ff9e71"></polygon>
    </svg>
  </div>
</div>

<IsMobile bind:isMobile />
