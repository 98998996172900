<style lang="scss">/* Bulma Utilities */
.title {
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", BlinkMacSystemFont, -apple-system, sans-serif;
  text-align: center;
  font-weight: 300;
  color: #2b3137;
  line-height: 0.8em;
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 9vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .title {
    font-size: 3em;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .title {
    font-size: 4em;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .title {
    font-size: 4em;
  }
}
@media screen and (min-width: 1408px) {
  .title {
    font-size: 4em;
  }
}
.title:last-child {
  margin-bottom: 0;
}</style>

<div>
  <h1 class="title">Partner Program</h1>
</div>
