<style lang="scss">/* Bulma Utilities */
.subtitle {
  font-size: 1em;
  color: #fff;
  margin-top: 4em;
  margin-left: 2em;
  margin-right: 2em;
  max-width: 26em;
}
@media screen and (max-width: 768px) {
  .subtitle {
    text-align: center;
    max-width: none;
  }
}</style>

<p class="subtitle">
  Help us test Creation Station BETA before official release. Check out the app
  using the button below.
</p>
