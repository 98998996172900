<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  import gsap from "gsap";
  import TextPlugin from "gsap/TextPlugin";
  gsap.registerPlugin(TextPlugin);

  import Logo from "./Logo.svelte";

  export let duration;
  export const TIMESCALE = 1.3 * 1.25;

  let animationContainer;
  let mouseInUse = false;
  let motionTimeout;

  const handleMouseMove = (e) => {
    clearTimeout(motionTimeout);
    mouseInUse = true;
    motionTimeout = setTimeout(() => {
      mouseInUse = false;
    }, 3000);
  };

  document.addEventListener("mousemove", handleMouseMove);

  $: if (animationContainer)
    animationContainer.style.cursor = mouseInUse ? "default" : "none";

  const TEXT_TRANSITION = 0.5;
  const TEXT_WAIT = 2.5;

  const TEXT1 = "When solving a challenge";
  const TEXT2 = "we come up with ideas";
  const TEXT3 = "lots of ideas...";
  const TEXT4 = "We need to capture all our ideas in one place";
  const TEXT5 = "Collaborate to refine,";
  const TEXT5_1 = "analyse,";
  const TEXT5_2 = "and organise ideas";
  const TEXT7 = "and transform them into amazing products and services!";
  const TEXT8 = "Collaborate remotely";
  const TEXT9 = "to unleash full creative potential.";
  const TEXT10 = "Your complete Design Thinking workstation.";

  const ASPECT_RATIO = 16 / 9;

  const EXPLOSION_DELAY = "<0.01";

  const MIDPOINT_X = 1600 / 2;
  const MIDPOINT_Y = 900 / 2;

  const SQUARE_MIDPOINT_X = MIDPOINT_X - 60;
  const SQUARE_MIDPOINT_Y = MIDPOINT_Y - 60;

  const LARGE_SQUARE_MIDPOINT_X = MIDPOINT_X + 360;
  const LARGE_SQUARE_MIDPOINT_Y = MIDPOINT_Y - 120;

  const ARC_A_X = MIDPOINT_X - 280;
  const ARC_A_Y = MIDPOINT_Y - 200;
  const ARC_B_X = MIDPOINT_X + 60;
  const ARC_B_Y = MIDPOINT_Y - 20;
  const TRANSLATE_1 = 80;

  const ORIGIN1 = "920 350";
  const ORIGIN2 = "660 530";

  let text1, text2, text3;
  let poly1,
    poly2,
    poly3,
    poly4,
    poly5,
    poly6,
    poly7,
    poly8,
    poly9,
    poly10,
    poly11,
    poly12,
    poly13;
  let polyA1,
    polyA2,
    polyA3,
    polyA4,
    polyA5,
    polyA6,
    polyA7,
    polyA8,
    polyA9,
    polyA10;
  let polyB1,
    polyB2,
    polyB3,
    polyB4,
    polyB5,
    polyB6,
    polyB7,
    polyB8,
    polyB9,
    polyB10;
  let logo;

  onMount(() => {
    gsap.defaults({
      duration: 1,
      ease: "power4",
    });

    const tl = gsap.timeline({ repeat: 0 });
    tl.timeScale(TIMESCALE);

    // DEBUG: Skip ahead and pause. Repeat must be enabled
    // tl.seek(30);
    // setTimeout(() => tl.pause(), 3000);

    // Initialise svg elements
    tl.set(
      poly1,
      {
        x: SQUARE_MIDPOINT_X,
        y: SQUARE_MIDPOINT_Y + 5,
      },
      "<"
    );

    // 1 second delay
    tl.to({}, {}, ">");

    // Solving text appears
    tl.fromTo(
      text1,
      { opacity: 0, x: -450, y: 5 - 200, text: TEXT1 },
      {
        opacity: 1,
        x: -450,
        y: 0 - 200,
        text: TEXT1,
        duration: TEXT_TRANSITION,
      },
      ">"
    );

    // Square fades in
    tl.to(poly1, { opacity: 1, y: 385 }, ">1");

    // Text appears right
    tl.fromTo(
      text2,
      { opacity: 0, x: 250, y: 5, text: TEXT2 },
      {
        opacity: 1,
        x: 250,
        y: 0,
        text: TEXT2,
        duration: TEXT_TRANSITION,
      },
      ">-0.9"
    );

    // Text fades out
    tl.to(
      [text1, text2],
      { opacity: 0, y: "+=" + 5, duration: TEXT_TRANSITION },
      ">" + TEXT_WAIT
    );

    // Explosion of cards
    tl.set(".explosion", { opacity: 1 }); // Unhide cards
    tl.fromTo(
      poly1,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "+=" + (Math.random() * 700 + 50),
        y: "+=" + (Math.random() * 300 + 100),
      },
      ">"
    );

    tl.fromTo(
      poly2,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "+=" + (Math.random() * 700 + 50),
        y: "+=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly3,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "+=" + (Math.random() * 700 + 50),
        y: "+=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly4,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "+=" + (Math.random() * 700 + 50),
        y: "+=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly5,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "-=" + (Math.random() * 700 + 50),
        y: "+=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly6,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "-=" + (Math.random() * 700 + 50),
        y: "+=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly7,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "-=" + (Math.random() * 700 + 50),
        y: "+=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly8,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "-=" + (Math.random() * 700 + 50),
        y: "-=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly9,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "-=" + (Math.random() * 700 + 50),
        y: "-=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly10,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "-=" + (Math.random() * 700 + 50),
        y: "-=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly11,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "+=" + (Math.random() * 700 + 50),
        y: "-=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly12,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "+=" + (Math.random() * 700 + 50),
        y: "-=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    tl.fromTo(
      poly13,
      { x: SQUARE_MIDPOINT_X, y: SQUARE_MIDPOINT_Y },
      {
        x: "+=" + (Math.random() * 700 + 50),
        y: "-=" + (Math.random() * 300 + 100),
      },
      EXPLOSION_DELAY
    );

    // Explosion text appears
    tl.fromTo(
      text1,
      { opacity: 0, x: 0, y: 5, text: TEXT3 },
      {
        opacity: 1,
        x: 0,
        y: 0,
        text: TEXT3,
        duration: TEXT_TRANSITION,
      },
      ">-0.1"
    );

    // Explosion text disappears
    tl.to(
      text1,
      { opacity: 0, y: 5, duration: TEXT_TRANSITION },
      ">" + TEXT_WAIT
    );

    // Second sentence
    tl.fromTo(
      text1,
      { opacity: 0, x: 0, y: 5, text: TEXT4 },
      {
        opacity: 1,
        x: 0,
        y: 0,
        text: TEXT4,
        duration: TEXT_TRANSITION,
      },
      ">"
    );

    // Explosion text disappears again
    tl.to(
      text1,
      { opacity: 0, y: 5, duration: TEXT_TRANSITION },
      ">" + TEXT_WAIT
    );

    // Squares become triangles
    tl.to(
      [poly1, poly2, poly3, poly4, poly11, poly12, poly13],
      { attr: { points: "50,50 0,100 100,100 100,0" } },
      ">"
    );

    tl.to(
      [poly5, poly6, poly7, poly8, poly9, poly10],
      { attr: { points: "0,0 0,100 50,50 100,0" } },
      "<"
    );

    // Triangle text appears
    tl.fromTo(
      text1,
      { opacity: 0, x: 0, y: 5, text: TEXT5 },
      {
        opacity: 1,
        x: 0,
        y: 0,
        text: TEXT5,
        duration: TEXT_TRANSITION,
      },
      ">-0.9"
    );

    // Unused triangles fade away
    tl.to(
      [poly2, poly3, poly4, poly6, poly9, poly10, poly12],
      { opacity: 0 },
      ">" + TEXT_WAIT / 2
    );

    // analyse
    tl.fromTo(
      text2,
      { opacity: 0, x: 150, y: 5, text: TEXT5_1 },
      {
        opacity: 1,
        x: 150,
        y: 0,
        text: TEXT5_1,
        duration: TEXT_TRANSITION,
      },
      "<"
    );

    tl.to(text1, { x: "-=" + 70, duration: TEXT_TRANSITION }, "<");

    // Triangles move into row
    tl.to(
      poly5,
      {
        x: SQUARE_MIDPOINT_X - 700,
        y: SQUARE_MIDPOINT_Y,
      },
      ">" + TEXT_WAIT / 2
    );
    tl.to(
      poly8,
      {
        x: SQUARE_MIDPOINT_X - 500,
        y: SQUARE_MIDPOINT_Y,
      },
      ">-0.9"
    );
    tl.to(
      poly7,
      {
        x: SQUARE_MIDPOINT_X - 300,
        y: SQUARE_MIDPOINT_Y,
      },
      ">-0.9"
    );
    tl.to(
      poly11,
      {
        x: SQUARE_MIDPOINT_X + 300,
        y: SQUARE_MIDPOINT_Y,
      },
      ">-0.9"
    );
    tl.to(
      poly1,
      {
        x: SQUARE_MIDPOINT_X + 500,
        y: SQUARE_MIDPOINT_Y,
      },
      ">-0.9"
    );
    tl.to(
      poly13,
      {
        x: SQUARE_MIDPOINT_X + 700,
        y: SQUARE_MIDPOINT_Y,
      },
      ">-0.9"
    );

    // and organise
    tl.fromTo(
      text3,
      { opacity: 0, x: 0, y: 55, text: TEXT5_2 },
      {
        opacity: 1,
        x: 0,
        y: 25,
        text: TEXT5_2,
        duration: TEXT_TRANSITION,
      },
      "<"
    );
    tl.to([text1, text2], { y: "-" + 15, duration: TEXT_TRANSITION }, "<");

    // Collaborate text disappears
    tl.to(
      [text1, text2, text3],
      { opacity: 0, y: "+=" + 5, duration: TEXT_TRANSITION },
      ">" + TEXT_WAIT / 2
    );

    // Triangles change colour
    tl.to(poly5, { fill: "#ffa379", y: SQUARE_MIDPOINT_Y - 150 }, ">");
    tl.to(poly8, { y: SQUARE_MIDPOINT_Y - 150 }, ">-0.9");
    tl.to(poly7, { fill: "#b879ff", y: SQUARE_MIDPOINT_Y - 150 }, ">-0.9");
    tl.to(poly11, { fill: "#53ebff", y: SQUARE_MIDPOINT_Y + 150 }, ">-0.9");
    tl.to(poly1, { fill: "#79b9ff", y: SQUARE_MIDPOINT_Y + 150 }, ">-0.9");
    tl.to(poly13, { fill: "#89ffbd", y: SQUARE_MIDPOINT_Y + 150 }, ">-0.9");

    // Color change text appears
    tl.fromTo(
      text1,
      {
        opacity: 0,
        x: 0,
        y: 5,
        text: TEXT7,
      },
      {
        opacity: 1,
        x: 0,
        y: 0,
        text: TEXT7,
        duration: TEXT_TRANSITION,
      },
      ">-0.9"
    );

    // Color change text disappears
    tl.to(text1, { opacity: 0, y: 5 }, ">" + TEXT_WAIT * 1.25);

    // Triangles collapse to only purple and green
    tl.to(
      [poly5, poly8, poly7],
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        scale: 2,
      },
      ">"
    );
    tl.to(
      [poly11, poly1, poly13],
      {
        x: ARC_B_X,
        y: ARC_B_Y, // +80
        scale: 2,
      },
      "<"
    );

    // Text before fan out
    tl.fromTo(
      text1,
      { opacity: 0, x: 0, y: 5, text: TEXT8 },
      {
        opacity: 1,
        x: 0,
        y: 0,
        text: TEXT8,
        duration: TEXT_TRANSITION,
      },
      ">-0.5"
    );

    // Text disappears
    tl.to(
      text1,
      { opacity: 0, y: 5, duration: TEXT_TRANSITION },
      ">" + TEXT_WAIT
    );

    // Triangles spread out in two arcs

    // Prepare hidden polys for arc animation
    tl.set(".arc", { opacity: 1, scale: 2 }, ">");

    // Polys in top arc use common svgOrigin
    tl.to(poly7, { y: "-=" + TRANSLATE_1 }, "<");
    tl.to(
      poly8,
      { y: "-=" + TRANSLATE_1, rotate: 15, svgOrigin: ORIGIN1 },
      "<"
    );
    tl.to(
      poly5,
      { y: "-=" + TRANSLATE_1, rotate: 30, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA1,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        fill: "#89ffbd",
      },
      { y: "-=" + TRANSLATE_1, rotate: 45, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA2,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        fill: "#79b9ff",
      },
      { y: "-=" + TRANSLATE_1, rotate: 60, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA3,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        fill: "#53ebff",
      },
      { y: "-=" + TRANSLATE_1, rotate: 75, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA4,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        fill: "#b879ff",
      },
      { y: "-=" + TRANSLATE_1, rotate: 90, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA5,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
      },
      { y: "-=" + TRANSLATE_1, rotate: 105, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA6,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        fill: "#ffa379",
      },
      { y: "-=" + TRANSLATE_1, rotate: 120, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA7,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        fill: "#89ffbd",
      },
      { y: "-=" + TRANSLATE_1, rotate: 135, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA8,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        fill: "#79b9ff",
      },
      { y: "-=" + TRANSLATE_1, rotate: 150, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA9,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        fill: "#53ebff",
      },
      { y: "-=" + TRANSLATE_1, rotate: 165, svgOrigin: ORIGIN1 },
      "<"
    );

    tl.fromTo(
      polyA10,
      {
        x: ARC_A_X,
        y: ARC_A_Y,
        fill: "#b879ff",
      },
      { y: "-=" + TRANSLATE_1, rotate: 180, svgOrigin: ORIGIN1 },
      "<"
    );

    // Polys in bottom arc use common svgOrigin
    tl.to(poly13, { y: "+=" + TRANSLATE_1 }, "<");
    tl.to(
      poly1,
      { y: "+=" + TRANSLATE_1, rotate: 15, svgOrigin: ORIGIN2 },
      "<"
    );
    tl.to(
      poly11,
      { y: "+=" + TRANSLATE_1, rotate: 30, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB1,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
        fill: "#b879ff",
      },
      { y: "+=" + TRANSLATE_1, rotate: 45, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB2,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
      },
      { y: "+=" + TRANSLATE_1, rotate: 60, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB3,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
        fill: "#ffa379",
      },
      { y: "+=" + TRANSLATE_1, rotate: 75, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB4,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
        fill: "#89ffbd",
      },
      { y: "+=" + TRANSLATE_1, rotate: 90, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB5,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
        fill: "#79b9ff",
      },
      { y: "+=" + TRANSLATE_1, rotate: 105, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB6,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
        fill: "#53ebff",
      },
      { y: "+=" + TRANSLATE_1, rotate: 120, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB7,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
        fill: "#b879ff",
      },
      { y: "+=" + TRANSLATE_1, rotate: 135, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB8,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
      },
      { y: "+=" + TRANSLATE_1, rotate: 150, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB9,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
        fill: "#ffa379",
      },
      { y: "+=" + TRANSLATE_1, rotate: 165, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      polyB10,
      {
        x: ARC_B_X,
        y: ARC_B_Y,
        fill: "#89ffbd",
      },
      { y: "+=" + TRANSLATE_1, rotate: 180, svgOrigin: ORIGIN2 },
      "<"
    );

    tl.fromTo(
      text1,
      {
        opacity: 0,
        x: 0,
        y: 5,
        text: TEXT9,
      },
      {
        opacity: 1,
        x: 0,
        y: 0,
        text: TEXT9,
        duration: TEXT_TRANSITION,
      },
      ">-0.5"
    );

    tl.to(
      text1,
      { opacity: 0, y: 5, duration: TEXT_TRANSITION },
      ">" + TEXT_WAIT
    );

    // End with fade transition
    // Fade out all polygons
    tl.to([".animation-container", ".paper"], { opacity: 0 }, ">");

    // Prepare scene for final animation section
    tl.set(".animation-container", { opacity: 1 }, ">");

    // Logo appears
    tl.fromTo(
      logo,
      {
        opacity: 0,
        x: 0,
        y: 5,
      },
      {
        opacity: 1,
        x: 0,
        y: 0,
        duration: 2,
      },
      ">"
    );

    // Tagline appears
    tl.fromTo(
      text1,
      {
        opacity: 0,
        x: 0,
        y: 115,
        fontSize: "1.83rem",
        text: TEXT10,
      },
      {
        opacity: 1,
        x: 0,
        y: 90,
        fontSize: "1.83rem",
        text: TEXT10,
        duration: TEXT_TRANSITION,
      },
      ">-0.9"
    );

    tl.to([logo, text1], { opacity: 0, y: "+=" + 5 }, ">2");

    duration = tl.totalDuration();
  });
</script>

<style lang="scss">/* Bulma Utilities */
.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.animation-container > svg {
  position: absolute;
}

.svg-text {
  font-size: 1.83em;
  text-anchor: middle;
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", BlinkMacSystemFont, -apple-system, sans-serif;
}

.paper {
  fill: #fee27c;
  opacity: 0;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}
.overlay button.button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.button {
  border: none;
  line-height: 19.07px;
}

.logo-container {
  display: flex;
  justify-content: center;
  transform: scale(0.405);
}</style>

<div class="animation-container" bind:this="{animationContainer}">
  <!-- Logo -->
  <!-- <div class="logo" bind:this="{logo}"> -->
  <svg
    class="logo-container"
    viewBox="0 0 1600 900"
    width="100%"
    height="100%"
    bind:this="{logo}">
    <Logo />
  </svg>
  <!-- </div> -->

  <!-- Main text elements -->

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <text
      bind:this="{text1}"
      class="svg-text"
      x="800"
      y="438"
      dominant-baseline="middle"></text>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <text
      bind:this="{text2}"
      class="svg-text"
      x="800"
      y="438"
      dominant-baseline="middle"></text>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <text
      bind:this="{text3}"
      class="svg-text"
      x="800"
      y="438"
      dominant-baseline="middle"></text>
  </svg>

  <!-- Cards used for arc animation need to be painted early, and in reverse order -->

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA10}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA9}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA8}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA7}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA6}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA5}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA4}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA3}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA2}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyA1}"
      class="paper arc"
      points="0,0 0,100 50,50 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB10}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB9}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB8}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB7}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB6}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB5}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB4}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB3}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB2}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{polyB1}"
      class="paper arc"
      points="50,50 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <!-- Cyan-coloured card used for explosion effect (3rd triangle bottom arc), needs to be painted before hero card -->
    <polygon
      bind:this="{poly11}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <!-- Hero card element -->

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon bind:this="{poly1}" class="paper" points="0,0 0,100 100,100 100,0"
    ></polygon>
  </svg>

  <!-- Cards used for explosion effect -->

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{poly2}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{poly3}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{poly4}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{poly5}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{poly6}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{poly8}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{poly9}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{poly10}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <polygon
      bind:this="{poly12}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <!-- Green hero card for arc animation -->
    <polygon
      bind:this="{poly13}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  <svg viewBox="0 0 1600 900" width="100%" height="100%">
    <!-- Purple hero card for arc animation -->
    <polygon
      bind:this="{poly7}"
      class="paper explosion"
      points="0,0 0,100 100,100 100,0"></polygon>
  </svg>

  {#if mouseInUse}
    <div class="overlay" transition:fade>
      <button
        class="button is-dark"
        on:click="{() => (window.location = window.location)}"
        ><strong>Skip</strong></button>
    </div>
  {/if}
</div>
