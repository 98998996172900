<style lang="scss">/* Bulma Utilities */
.title-container {
  padding-left: 5vw;
  padding-right: 5vw;
}

.title {
  text-align: center;
  font-weight: 300;
  color: #2b3137;
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", BlinkMacSystemFont, -apple-system, sans-serif;
  line-height: 1em;
  margin-bottom: 0.1em;
}
.title strong {
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 9vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .title {
    font-size: 4.5em;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .title {
    font-size: 4.5em;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .title {
    font-size: 4.5em;
  }
}
@media screen and (min-width: 1408px) {
  .title {
    font-size: 5vw;
  }
}
.title:last-child {
  margin-bottom: 0;
}</style>

<div class="title-container">
  <h1 class="title rellax" data-rellax-speed="3">Design Thinking,</h1>
  <h1 class="title rellax" data-rellax-speed="2">
    <strong>made easy.</strong>
  </h1>
</div>
